.wrapper {
  height: 100%;
  background-color: #000000;
}

.wrapper-content {
  height: 100%;
}

.replay-header {
  color: black;
  background-color: #BBBBBB;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 3vh;
  display: flex;
  flex-direction: column;
  padding-bottom: .2vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: calc(5px + 1vmin);
}

.wrapper-footer {
  color: white;
  background-color: black;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: column;
  padding-bottom: .3vh;
}

.wrapper-footer .info {
  width: 95%;
  margin-left: 3%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
}

.wrapper-footer .info .home {
  text-align: center;
  white-space: nowrap;
  width: 60px;
}

.wrapper-footer .info .balance {
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
  width: 200px;
}

.wrapper-footer .info .wins {
  width: 100%;
}

.wrapper-footer .info .cost {
  text-align: right;
  margin-right: 10px;
  white-space: nowrap;
  width: 200px;
}

.wrapper-footer .controls {
  width: 95%;
  margin-left: 3%;
  display: flex;
  flex-direction: row;
  padding-top: .2vh;
}

.wrapper-footer .controls .home {
  text-align: center;
  margin-top: 5px;
  white-space: nowrap;
  width: 60px;
}

.wrapper-footer .controls .spacer {
  width: 100%;
  text-align: center;
  font-size: calc(5px + 1vmin);
}

.wrapper-footer .controls .left-controls {
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
  width: 200px;
  font-size: calc(5px + 1vmin);
}

.wrapper-footer .controls .left-controls .control {
  width: 3%;
  min-width: 15px;
  font-size: calc(5px + 1vmin);
}

.wrapper-footer .controls .right-controls {
  text-align: right;
  margin-right: 10px;
  white-space: nowrap;
  width: 200px;
  font-size: calc(5px + 1vmin);
  white-space: nowrap;
  min-width: 80px;
}

.modal {
  font-size: 12px;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  width: 35px;
  height: 35px;
}

.popup-content {
  border-radius: 5px;
  padding: 0.5rem;
  background-color: rgb(41, 45, 62);
  color: #fff;
  border: 1px solid #9a9595;
  position: relative;
  margin: auto;
  max-width: 600px;
  width: 90%;
  pointer-events: auto;
}

.popup-arrow > svg {
  color: rgb(41, 45, 62);
  stroke-width: 2px;
  stroke: #9a9595;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
}

.popup-overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
}

.no-interaction {
  pointer-events: none;
  user-select: none;
  z-index: -1;
}